<script lang="ts">
  import {
    PUBLIC_SITE_NAME,
    PUBLIC_SITE_DESCRIPTION,
    PUBLIC_SITE_URL,
    PUBLIC_SITE_IMAGE,
    PUBLIC_IMAGE_BASE_URL,
    PUBLIC_TWITTER_HANDLE,
    PUBLIC_TWITTER_SITE,
    PUBLIC_TWITTER_CARD_TYPE
  } from '$env/static/public';
  import { page } from '$app/stores';

  export let title = '';
  export let description = PUBLIC_SITE_DESCRIPTION;
  export let keywords = '';
  export let images = [];
  export let og = true;
  export let twitter = true;

  let titleTemplate = `%s | ${PUBLIC_SITE_NAME}`;

  if (!title) {
    title = PUBLIC_SITE_NAME;
    titleTemplate = '%s';
  }

  if (images.length > 0) {
    images = images.map((i) => {
      if (!i.url.startsWith('http')) {
        i.url = `${PUBLIC_IMAGE_BASE_URL}${i.url}`;
      }
      i.alt = i.alt || description;
      return i;
    });
  } else {
    images = [
      {
        url: PUBLIC_SITE_IMAGE,
        alt: PUBLIC_SITE_NAME
      }
    ];
  }

  const ogData = og
    ? {
        url: $page.url.href,
        title: title,
        description: description,
        images: images,
        siteName: PUBLIC_SITE_NAME
      }
    : undefined;

  const twitterData = twitter
    ? {
        handle: PUBLIC_TWITTER_HANDLE || '',
        site: PUBLIC_TWITTER_SITE || '',
        cardType: PUBLIC_TWITTER_CARD_TYPE || '',
        title: `Learn ${title}`,
        description: `Master interactive chart creation with Svelte and D3. Discover how to create ${title} for dynamic and interactive visualizations.`,
        image: images[0].url,
        imageAlt: images[0].alt
      }
    : undefined;
</script>

<svelte:head>
  <title>{title}</title>
  <meta name="description" content={description} />
  <meta name="keywords" content={keywords} />
  <link rel="canonical" href={$page.url.origin + $page.url.pathname} />

  <!-- Open Graph Metadata -->
  {#if ogData}
    <meta property="og:url" content={ogData.url} />
    <meta property="og:title" content={ogData.title} />
    <meta property="og:description" content={ogData.description} />
    <meta property="og:site_name" content={ogData.siteName} />
    <meta property="og:image" content={ogData.images[0].url} />
    <meta property="og:image:alt" content={ogData.images[0].alt} />
  {/if}

  <!-- Twitter Card Metadata -->
  {#if twitterData}
    <meta name="twitter:card" content={twitterData.cardType} />
    <meta name="twitter:site" content={twitterData.site} />
    <meta name="twitter:creator" content={twitterData.handle} />
    <meta name="twitter:title" content={twitterData.title} />
    <meta name="twitter:description" content={twitterData.description} />
    <meta name="twitter:image" content={twitterData.image} />
    <meta name="twitter:image:alt" content={twitterData.imageAlt} />
  {/if}
</svelte:head>
